/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import { HeaderView } from './header-view'
import { WhatWeDo } from './whatwedo-view'
import { CasesView } from './cases-view'
import { ContactView } from './contact-view'
import { FooterView } from './footer-view'

export const View = (state, actions) => (
  <div
    class='body'
    oncreate={el => actions.initSwipeSupport(el)}
    onclick={e => !state.isClosed ? actions.set({ isClosed: true }) : null}
  >
    <HeaderView actions={actions} state={state} />
    <WhatWeDo />
    <CasesView />
    <ContactView />
    <FooterView />
  </div>
)

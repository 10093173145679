/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import Linea from '../images/lineahemma.jpg'
import Knittingroom from '../images/knittingroom.jpg'
import Ashild from '../images/ashild.jpg'
import Margaretha from '../images/margaretha.jpg'
import Gleerups from '../images/gleerups.jpg'
import Apladmin from '../images/apladmin.jpg'
import Ekelund from '../images/ekelunds.jpg'

const cases = [
  {
    name: 'Gleerups portal',
    image: Gleerups,
    type: 'Educational platform',
    copy: 'Licensing and user management system, provisioning integrations',
    url: 'gleerupsportal.se'
  },
  {
    name: 'Linea',
    image: Linea,
    type: 'E-commerce',
    copy: 'Interior design in four countries, e-commerce solution with CMS and business integration',
    url: 'lineahemma.se'
  },
  {
    image: Knittingroom,
    name: 'Knittingroom',
    type: 'E-commerce',
    copy: 'Needlework shop in four countries, e-commerce solution with CMS and business integration',
    url: 'knittingroom.se'
  },
  {
    name: 'Åshild',
    image: Ashild,
    type: 'E-Commerce',
    copy: 'Womens clothing in four countries, e-commerce solution with CMS and business integration',
    url: 'ashild.se'
  },
  {
    name: 'Ateljé Margaretha',
    image: Margaretha,
    type: 'E-Commerce',
    copy: 'Needlework shop in six countries, e-commerce solution with CMS and business integration',
    url: 'margaretha.se'
  },
  {
    name: 'APL-admin',
    image: Apladmin,
    type: 'Administration',
    copy: 'SAAS administration system for Swedish schools',
    url: 'apladmin.se'
  }
]

const CaseCard = (props) => (
  <div class='column'>
    <div class='card'>
      <div class='card-image'>
        <figure class='image '>
          <img src={props.image} alt={props.name} />
        </figure>
      </div>
      <div class='card-content'>
        <div class='media'>
          <div class='media-content'>
            <p class='title is-4'>{props.name}</p>
            <p class='subtitle is-6'>
              {props.type}
            </p>
          </div>
        </div>

        <div class='content'>
          {props.copy}
          <br />
          <p class='subtitle is-6'>
            <a href={'https://' + props.url} target='_blank' rel='noopener noreferrer'>
              {props.url}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export const CasesView = () => (
  <section id='cases' class='page container'>
    <div class='columns'>
      <h1 class='subtitle'>Some things that we are proud of</h1>
    </div>
    <div class='columns'>
      {
        cases
          .map(props => CaseCard(props))
      }
      <div class='column' />

      <div class='column' />

    </div>
  </section>
)

import VanillaScrollspy from 'vanillajs-scrollspy'
import TinyGesture from 'tinygesture'

let scrollspy
let gesture

export const actions = {

  set: x => x,

  initScrollspy: (el) => (state, actions) => {
    scrollspy = new VanillaScrollspy(el, 400, 'easeInOutQuint')
    scrollspy.init()
  },

  initSwipeSupport: (el) => (state, actions) => {
    gesture = new TinyGesture(el)
    gesture.on('swiperight', event => {
      if (state.isClosed) {
        actions.set({
          isClosed: false
        })
      }
    })
    gesture.on('swipeleft', event => {
      actions.set({
        isClosed: true
      })
    })
  }
}

/* eslint-disable no-unused-vars */
import { app } from 'hyperapp'
import { actions } from './actions/'
import { View } from './views/'
import './styles/app.scss'

const state = {
  isClosed: true
}

app(state, actions, View, document.querySelector('#app'))

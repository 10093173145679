/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

const lingo = [
  'After several years of successful collaboration Aron Bergström and Martin Walker decided to merge and founded ITCM AB in the fall of 2015. We specialize in modern and efficient system design for large and complex systems.',
  "ITCM is behind much of the architecture that runs one of Sweden's largest digital educational publisher Gleerups.",
  'With over a quarter of a million users, high demands are placed on an intuitive, functional and responsive interface that meets modern design and system requirements.',
  'We run market research and users panels for large retail corporations.',
  'Our portfolio also includes web-based visualization of Big Data, web-based games for Alinea in Denmark, integration between different system registries, Single Sign On solutions and business systems.'
]

const LingoLine = (str) => <p class='padded'>{str}</p>

export const WhatWeDo = () => (
  <section id='whatwedo' class='page container'>
    <div class='columns'>
      <div>
        <h1 class='subtitle'>What we do</h1>
        {
          lingo
            .map(str => LingoLine(str))
        }
      </div>
    </div>
  </section>
)

/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import Martin from '../images/martin.jpg'
import Aron from '../images/aron.jpg'
import Andreas from '../images/andreas.jpg'
import None from '../images/none.jpg'

// TODO: add more staff here
const staff = [
  {
    name: 'Martin Walker',
    image: Martin,
    email: 'martin@itcm.se',
    title: 'CEO',
    tagline: '25 years of IT experience, PHP, MySQL, system integrations',
    tel: '734048653',
    formattedTel: '+46 (0)734 04 86 53'
  },
  {
    name: 'Aron Bergström',
    image: Aron,
    email: 'aron@itcm.se',
    title: 'Systems architect',
    tagline: 'Fullstack & Certified AWS Solutions Architect.',
    tel: '720645106',
    formattedTel: '+46 (0)720 64 51 06'
  },
  {
    name: 'Simon Lundquist',
    image: None,
    email: 'simon@itcm.se',
    title: 'Systems architect',
    tagline: 'Fullstack developer'
  },
  {
    name: 'Filip Larsson',
    image: None,
    email: 'filip@itcm.se',
    title: 'Junior developer',
    tagline: 'Frontend/backend developer & Certified Pimcore Developer Junior.'
  },
  {
    name: 'Andreas Makslahti',
    image: Andreas,
    email: 'andreas@itcm.se',
    title: 'Junior UI/UX Designer',
    tagline: 'React, Vue, Angular, Twitter Bootstrap 5 - Located in Umeå'
  }
]

const StaffCard = (props) => (
  <div class='column'>
    <div class='card'>
      <div class='card-image'>
        <figure class='image '>
          <img src={props.image} alt={props.name} />
        </figure>
      </div>
      <div class='card-content'>
        <div class='media'>
          <div class='media-content'>
            <p class='title is-4'>{props.name}</p>
            <p class='subtitle is-6'>
              <a href={`mailto:${props.email}`}>
                {props.email}
              </a>
              <br />
              <a href={`tel:+46${props.tel}`}>
                {props.formattedTel ? props.formattedTel : h('br')}
              </a>
            </p>
          </div>
        </div>
        <div class='content'>
          <b>{props.title}</b>
          <p>
            {props.tagline}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export const ContactView = () => (
  <section id='contact' class='page container'>
    <h1 class='subtitle'>Contact us</h1>
    <div class='columns'>
      {
        staff
          .map(props => StaffCard(props))
      }
    </div>
  </section>
)

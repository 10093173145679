/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'
import Logo from '../images/logo.png'

const SetActive = (obj) => {
  if (obj.active) {
    obj.el.classList.add('is-closed')
  } else {
    obj.el.classList.remove('is-closed')
  }
}

export const HeaderView = ({ state, actions }) => (
  <nav class='navbar is-fixed-top' role='navigation' aria-label='main navigation'>
    <div class='navbar-brand'>
      <div class='logo'>
        <img src={Logo} alt='ITCM logo' />
      </div>
      <a role='button' class='navbar-burger' aria-label='menu' aria-expanded='false' onclick={e => actions.set({ isClosed: !state.isClosed })}>
        <span aria-hidden='true' />
        <span aria-hidden='true' />
        <span aria-hidden='true' />
      </a>
    </div>

    <div id='navbar' class='navbar-menu navbar-scroll is-closed' onupdate={el => SetActive({ el: el, active: state.isClosed })} oncreate={el => actions.initScrollspy(el)}>
      <div class='navbar-end' onclick={e => actions.set({ isClosed: !state.isClosed })}>
        <a href='#whatwedo' class='nav-item active'>
          <span>What we do</span>
        </a>
        <a href='#cases' class='nav-item'>
          <span>Cases</span>
        </a>
        <a href='#contact' class='nav-item'>
          <span>Contact</span>
        </a>
      </div>
    </div>
  </nav>
)

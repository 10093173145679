/* eslint-disable no-unused-vars */
import { h } from 'hyperapp'

export const FooterView = () => (
  <footer class='footer'>
    <div class='columns container'>
      <div class='column'>
        <ul>
          <li>
Information Technology Consulting i Mark AB
          </li>
          <li>
Org.nummer: 559027-4287
          </li>
          <li>
            <a href='https://www.allabolag.se/5590274287/information-technology-consulting-i-mark-ab'>
Financial information (allabolag.se)
            </a>
          </li>
        </ul>
      </div>
      <div class='column'>
        <ul>
          <li>
    KYRKOGATAN 3
          </li>
          <li>
511 54 Kinna
          </li>
          <li>
            <a href='https://www.google.se/maps/place/Kyrkogatan+3,+511+54+Kinna'>
Directions
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)
